<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
    >
        <div class="modal-card modal-content-width">
            <section class="modal-card-head pb-0">
                <p class="font-bold text-2xl">Cuentas de banco del acreditado</p>
                <button
                type="button"
                class="delete absolute right-0 mr-5"
                @click="$parent.close()"
                />
            </section>
            <section class="modal-card-body has-background-white text-left">
                <div class="p-1">
                    <ValidAutoComplete
                        label="Cuenta de banco del acreditado"
                        placeholder="Buscar..."
                        rules="required"
                        show="cuenta"
                        :custom="true"
                        :data="getPersonaCuentasBancarias"
                        :expanded="true"
                        @select="(value) => persona_cuenta_banco = value"
                    >
                        <template v-slot:custom="slotProps">
                            <div>
                                <p class="has-text-grey-darker-always">
                                    Cuenta #{{ slotProps.option.option.cuenta }}
                                </p>
                                <p class="has-text-grey-darker-always">
                                    CLABE #{{ slotProps.option.option.CLABE }}
                                </p>
                                <p class="has-text-grey-darker-always">
                                    Institución #{{ slotProps.option.option.institucion.nombre }}
                                </p>
                            </div>
                        </template>
                    </ValidAutoComplete>
                </div>
            </section>
            <section class="modal-card-foot justify-end">
                <b-button
                    type="is-primary"
                    class="font-bold"
                    :disabled="loading"
                    @click="validate().then(result => agregarCuenta(result))"
                >
                Agregar
                </b-button>
            </section>
        </div>
    </ValidationObserver>
</template>
    
<script>
    import DisposicionApi from "@/api/disposicion.api";

    import { mapGetters } from "vuex";
    import { ValidationObserver } from "vee-validate";
    
    import ValidAutoComplete from "@/components/form/ValidAutoComplete";
    
    export default {
        name: "ModalCuentaBancoAcreditado",
        components: {
            ValidationObserver,
            ValidAutoComplete,
        },
        props: {
            acreditado_id: Number,
        },
        data() {
            return{
                persona_cuenta_banco: null,
                loading: false,
            }
        },
        computed: {
            ...mapGetters("personas", ["getPersonaCuentasBancarias"]),
        },
        methods: {
            async agregarCuenta(validated){
                if (! validated || ! this.persona_cuenta_banco?.id) return;
    
                this.loading = true;
    
                await DisposicionApi.agregarCuentaBancoAcreditado({
                    disposicion_id: this.$route.params.idDisposicion,
                    cuenta_banco_id: this.persona_cuenta_banco.id,
                });

                await this.$store.dispatch(
                    "disposicion/getDisposicionDetalle",
                    this.$route.params.idDisposicion
                );
    
                this.loading = false;

                this.$parent.close();
            },
        },
        created() {
            this.$store.dispatch("personas/getPersonaCuentasBancarias", this.acreditado_id);
        }
    }
</script>
<template>
  <div>
    <div class="columns is-multiline">
      <div class="column">
        <monto-card
          :capital="disposicion.capital"
          :moneda="disposicion.moneda.clave"
          :pasiva="disposicion.pasiva != 0 ? true : false"
          :producto="disposicion.producto_financiero.alias"
          :status="disposicion.credito_status.nombre"
          :arrendamiento="arrendamiento"
          :sucursal="disposicion.sucursal.nombre"
        />
      </div>
      <div class="column">
        <status-card
          :status="disposicion.credito_status"
          :tieneLinea="disposicion.linea_credito.length > 0"
          :fecha_entrega="disposicion.fecha_entrega"
          :pasiva="parseInt(disposicion.pasiva)"
        />
      </div>
      <div class="column" v-if="disposicion.linea_credito.length > 0">
        <contrato-linea-card
          :linea="disposicion.linea_credito"
          :ejecutivo="disposicion.ejecutivo"
        />
      </div>
    </div>
    <div class="columns is-multiline pb-8">
      <div class="column is-12" v-if="arrendamiento && disposicion.garantias">
        <p class="text-2xl font-bold text-left">Activo del arrendamiento</p>
        <garantia-card :garantia="disposicion.garantias[0]" :arrendamiento="arrendamiento" :arrendando="true" />
      </div>
      <div class="column is-12" v-if="disposicion.acreditado">
        <acreditado-card
          :acreditado="disposicion.acreditado"
          :ejecutivo="disposicion.ejecutivo"
          :relaciones="disposicion.acreditado.relaciones"
        />
      </div>
      <div v-if="arrendamiento" class="column is-12 text-left">
        <detalle-card
          title="Configuración del arrendamiento"
          :data="detalle_arrendamiento"
        />
      </div>
      <div class="column is-12 text-left">
        <detalle-card
          title="Configuración del capital"
          :data="tratamiento_capital"
        />
      </div>
      <div class="column is-12 text-left" v-if="disposicion.interes_ordinario">
        <detalle-card
          title="Configuración del Interés ordinario"
          :data="interes_ordinario"
        />
      </div>
      <div class="column is-12 text-left" v-if="disposicion.interes_moratorio">
        <detalle-card
          title="Configuración del Interés moratorio"
          :data="interes_moratorio"
        />
      </div>
      <div
        class="column is-12 text-left"
        v-if="disposicion.fecha_entrega == null"
      >
        <accesorios-card
          :accesorios="disposicion.costos_extra"
          :capital="disposicion.capital"
          :moneda="disposicion.moneda.clave"
          :iva="disposicion.sucursal ? disposicion.sucursal.iva : null"
        />
      </div>
      <div
        class="column is-12 text-left"
        v-if="disposicion.destino_recursos_id"
      >
        <detalle-card title="Extras" :data="extras" />
      </div>
    </div>
  </div>
</template>

<script>
import MontoCard from "@/components/cards/MontoCard";
import ContratoLineaCard from "@/components/cards/ContratoLineaCard";
import StatusCard from "@/components/cards/StatusCard";
import AcreditadoCard from "@/components/cards/AcreditadoCard";
import DetalleCard from "@/components/cards/DetalleCard";
import AccesoriosCard from "@/components/cards/AccesoriosCard";
import GarantiaCard from "@/components/cards/GarantiaCard";
import { mapGetters } from "vuex";

export default {
  name: "Informacion",
  components: {
    MontoCard,
    ContratoLineaCard,
    StatusCard,
    DetalleCard,
    AcreditadoCard,
    AccesoriosCard,
    GarantiaCard
  },
  props: {
    disposicion: Object,
    arrendamiento: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("disposicion", ["getChangeStatus"]),
    ...mapGetters(["getSofom"]),

    detalle_arrendamiento(){
      return this.disposicion.arrendamiento 
        ? [
            {
              title: "Valor del bien",
              value: `$${this.disposicion.arrendamiento.valor_del_bien} ${this.disposicion.moneda.clave}`
            },
            {
              title: "Valor residual",
              value: `${this.disposicion.arrendamiento.valor_residual}%`
            },
            {
              title: "Enganche",
              value: `$${this.disposicion.arrendamiento.enganche} ${this.disposicion.moneda.clave}`
            },
          ]
        : null;
    },
    tratamiento_capital() {
      return [
        {
          title: "Tipo de cobro",
          value: this.disposicion.tipo_pago.alias
        },
        {
          title: "Tipo de periodo regular",
          value: this.disposicion.periodos_regulares ? this.disposicion.periodos_regulares.periodo_regular.nombre : 'N/A',
          hidden: !this.disposicion.periodos_regulares
        },
        {
          title: "Plazo en dias",
          value: this.disposicion.plazo_dias
        },
        {
          title: "Fecha de vencimiento",
          value: this.$moment(this.disposicion.fecha_vencimiento).format(
            "DD-MM-YYYY"
          )
        }
      ];
    },
    interes_ordinario() {
      return [
        {
          title: "Tasa base",
          value: this.disposicion.interes_ordinario.tasa_base
            ? this.disposicion.interes_ordinario.tasa_base.nombre
            : "N/A"
        },
        {
          title: "Sobre tasa",
          value: this.disposicion.interes_ordinario.cantidad + "%"
        },
        {
          title: "Tipo de cobro",
          tooltip:
            "En caso de existir un día inhabil en cualquier vencimiento, esta es la manera en que actuará el sistema",
          value: `${
            this.disposicion.tipo_tratamiento_interes
              ? this.disposicion.tipo_tratamiento_interes.tipo + " con"
              : ""
          } 
            ${
              this.disposicion.dia_inhabil
                ? "Día habil siguiente"
                : "Día habil anterior"
            }`
        },
        {
          title: "Fecha de corte",
          value: this.disposicion.corte_aniversario
            ? "Fecha de aniversario"
            : "Fin de mes"
        },
        {
          title: "Forma de cálculo",
          value: this.disposicion.entrada_si_salida_no
            ? "Entrada sí, salida no"
            : "Entrada no, salida sí"
        },
        {
          title: "IVA",
          value: this.disposicion.iva_interes_ordinario ? "Sí" : "No"
        },
        {
          title: "CAT",
          value: `${this.disposicion.cat || 0}%`,
          underlined: true,
          tooltip:
            "Se calcula al inicio de la disposición y no vuelve a cambiar."
        },
        {
          title: "Dias antes de provisión anticipada",
          value:
            this.disposicion.producto_financiero.provision_anticipada == 1 &&
            this.disposicion.credito_core.dias_antes_provision >= 0
              ? this.disposicion.credito_core.dias_antes_provision
              : "N/A"
        },
        {
          title: "Base de cálculo",
          value: this.disposicion.producto_financiero.base_intereses
            ? `${this.disposicion.producto_financiero.base_intereses.base} días`
            : 'N/A'
        },
        {
          title: "Refinanciamiento de intereses",
          value: this.disposicion.refinanciamiento_intereses == 1 ? 'Si' : 'No'
        },
        {
          title: "Refinanciamiento vencido",
          value: this.disposicion.refinanciamiento_vencido == 1 ? 'Si' : 'No',
          hidden: !this.disposicion.refinanciamiento_intereses
        },
      ];
    },
    interes_moratorio() {
      if (this.disposicion.interes_moratorio.es_en_base_factor) {
        let iva = this.disposicion.iva_interes_moratorio
          ? "con IVA"
          : "sin IVA";
        let valor = "";
        if (this.disposicion.interes_moratorio.cantidad == 1) {
          valor =
            "" +
            this.disposicion.interes_moratorio.cantidad.toString() +
            " vez el interes ordinario " +
            iva;
        } else {
          valor =
            "" +
            this.disposicion.interes_moratorio.cantidad.toString() +
            " veces el interes ordinario " +
            iva;
        }
        return [
          {
            title: "Tipo de interés",
            value: valor
          }
        ];
      } else {
        const tasa_base = this.disposicion.interes_moratorio.tasa_base?.nombre ?? 'Tasa Fija';

        return [
          {
            title: "Tasa Base",
            value: this.disposicion.interes_moratorio
              ? tasa_base
              : this.disposicion.interes_ordinario.tasa_base.nombre
          },
          {
            title: "Sobre tasa",
            value: this.disposicion.interes_moratorio
              ? this.disposicion.interes_moratorio.cantidad + "%"
              : this.disposicion.interes_ordinario.cantidad + "%"
          },
          {
            title: "Veces que se multiplica la tasa base",
            value: this.disposicion.interes_moratorio.cantidad,
            hidden: this.disposicion.interes_moratorio.es_en_base_factor
              ? false
              : true
          },
          {
            title: "IVA",
            value: this.disposicion.iva_interes_moratorio ? "Sí" : "No"
          }
        ];
      }
    },
    extras() {
      return [
        {
          title: "Destino de los recursos",
          value: this.disposicion.destino_recursos.nombre
        },
        {
          title: "Localidad",
          value: this.disposicion.localidad
            ? `${this.disposicion.localidad.nombre}, ${this.disposicion.localidad.municipio.nombre}, ${this.disposicion.localidad.estado.nombre}`
            : "N/A",
          hidden: this.disposicion.localidad ? false : true
        },
        {
          title: "Cuenta de Banco",
          value:
            "<b>Cuenta:</b> " +
            (this.disposicion.cuentas_banco != null
              ? this.disposicion.cuentas_banco.cuenta
              : " ") +
            "<br/><b>CLABE:</b> " +
            (this.disposicion.cuentas_banco != null
              ? this.disposicion.cuentas_banco.CLABE
              : ""),
          hidden: this.disposicion.cuentas_banco ? false : true
        },
        {
          title: "Moneda",
          value: this.disposicion.moneda ? this.disposicion.moneda.nombre : ""
        },
        {
          title: "Instrumento Monetario",
          value: this.disposicion.instrumento_monetario
            ? this.disposicion.instrumento_monetario.nombre
            : "",
          hidden: this.disposicion.instrumento_monetario ? false : true
        },
        {
          title: "Referencia bancaria del pago",
          value: this.disposicion.referencia_bancaria_pago,
          hidden: this.disposicion.referencia_bancaria_pago ? false : true
        },
        {
          title: "Interes ordinario de recursos ajenos",
          value: this.disposicion.interes_ordinario_ra
            ? this.disposicion.interes_ordinario_ra.cantidad +
              (this.disposicion.interes_ordinario_ra.iva
                ? "% con IVA"
                : "% sin IVA")
            : "",
          hidden: this.disposicion.interes_ordinario_ra ? false : true
        },
        {
          title: "Cuenta de banco del acreditado",
          value:
            "<b>Cuenta:</b> " +
            (this.disposicion.cuenta_banco_persona != null
              ? this.disposicion.cuenta_banco_persona.cuenta
              : "Otra Cuenta") +
            "<br/><b>CLABE:</b> " +
            (this.disposicion.cuenta_banco_persona != null
              ? this.disposicion.cuenta_banco_persona.CLABE
              : "No aplica"),
        },
      ];
    }
  }
};
</script>
